<template>
  <div id="main-menu" :class="{ 'active' : isMenuOpen }">

    <div class="menu-header">
      <a href="javascript:void(0);" class="close-trigger" @click="toggleMenu">&times;</a>
      <span>Menu</span>
      <a href="https://brydg.com" target="_blank">
        <img src="../assets/badge.svg" alt="Logo" />
      </a>
    </div>

    <div class="menu-container">
      <ul class="menu">
        <li><a href="https://brydg.com/capital" target="_blank"><span>Brydg Capital</span></a></li>
        <ul>
        <!-- <li><a href="https://brydg.com/cbils" target="_blank">Coronavirus Business Interuption Loan Scheme</a></li> -->
          <li><a href="https://flow.brydg.com/investors/dashboard" target="_blank">Investor Dashboard</a></li>
        </ul>
        <li><a href="https://brydg.com/exchange" target="_blank"><span>The Marketplace</span></a></li>
        <ul>
          <li><a href="https://flow.brydg.com" target="_blank">Broker's Portal</a></li>
          <li><a href="https://brydg.com/lenders" target="_blank">Become a Lender</a></li>
        </ul>
        <li><a href="https://brydg.com/about" target="_blank"><span>About us</span></a></li>
        <ul>
          <li><a href="https://brydg.com/newsroom" target="_blank"><span>Newsroom</span></a></li>
          <li><a href="https://pulse.brydg.com" target="_blank"><span>Pulse Blog</span></a></li>
          <li><a href="https://brydg.com/philanthropy" target="_blank"><span>Philanthropy</span></a></li>
        </ul>
        <li><a href="https://brydg.com/area/UK" target="_blank"><span>Area Guide <sup>beta</sup></span></a></li>
        <li><a href="https://brydg.com/shop" target="_blank"><span>Merchandise Shop</span></a></li>
      </ul>
    </div>

    <div class="menu-footer clearfix">

      <h3>Connect</h3>

      <div class="newsletter">
        <form action="https://brydg.us13.list-manage.com/subscribe/post?u=60bcc3d9308ec5d8d42566461&amp;id=0e6e564faf" method="post" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <input type="email" value="" name="EMAIL" class="email" placeholder="email address" required>
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_60bcc3d9308ec5d8d42566461_0e6e564faf" tabindex="-1" value=""></div>
          <div class="clear"><input type="submit" value="Subscribe" name="subscribe" class="button"></div>
        </form>
      </div>

      <div class="social-media">
        <a href="https://www.facebook.com/brydg/" target="_blank"><i class="fab fa-facebook-square"></i></a>
        <a href="https://twitter.com/brydgcom" target="_blank"><i class="fab fa-twitter-square"></i></a>
        <a href="https://instagram.com/brydg_" target="_blank"><i class="fab fa-instagram"></i></a>
        <a href="https://www.linkedin.com/company/brydg/" target="_blank"><i class="fab fa-linkedin"></i></a>
        <a href="https://www.youtube.com/channel/UC1xkXZ7OHoqHDDQzw3Vr84w" target="_blank"><i class="fab fa-youtube"></i></a>
        <a href="https://brydg.eventbrite.com/o/brydgcom-17476202033" target="_blank"><img style="width:25px;vertical-align:baseline;" src="../assets/eventbrite.png"></a>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'MainMenu',
  props: {
    isMenuOpen: Boolean
  },
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*----------------
main menu
-----------------*/

#main-menu {
	/* display: none; changed to width animation */
  font-family: 'Lato', sans-serif;
	position: fixed;
	z-index: 101;
	width: 0;
	height: 100%;
	background: #312b1b;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 0;
	overflow-y: auto;
	overflow-x: hidden;
  line-height: 1.5;
  transition: width .5s ease-in-out;
  white-space: nowrap;
}
#main-menu.active {
  width: 100%;
}
@media only screen and (min-width: 768px) {
	#main-menu {
		max-width: 350px;
	}
}
/* width */
#main-menu::-webkit-scrollbar {
	width: 6px;
}
/* Track */
#main-menu::-webkit-scrollbar-track {
	background: transparent; 
}
/* Handle */
#main-menu::-webkit-scrollbar-thumb {
	background: #c3ac6b;
	border-radius: 5px;
}

/*----------------
main menu - header
-----------------*/

#main-menu .close-trigger {
	display: block;
	float: left;
	font-size: 35px;
	color: #fff;
	text-decoration: none;
	line-height: 56px;
	width: 56px;
	height: 56px;
	text-align: center;
}
#main-menu .close-trigger:hover {
	text-decoration: none;
}
#main-menu .menu-header {
	position: relative;
	background: #000;
}
#main-menu .menu-header::after {
	content: "";
	display: table;
	clear: both;
}
#main-menu .menu-header span {
	display: block;
	float: left;
	width: calc(100% - 56px);
	color: #fff;
	font-size: 20px;
	line-height: 56px;
}
#main-menu .menu-header img {
	width: 35px;
	height: auto;
	position: absolute;
	right: 10px;
	top: 11.5px;
}

/*----------------
main menu - container
-----------------*/

#main-menu .menu-container {
	padding-bottom: 40px;
	background: #000;
}
#main-menu .menu-container .menu {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-left: 56px;
}
#main-menu .menu-container .menu::after {
	content: "";
	display: table;
	clear: both;
}
#main-menu .menu-container .menu li {
	font-size: 19px;
	padding-top: 10px;
	margin-bottom: 10px;
	border-top: solid 1px #575757;
}
#main-menu .menu-container .menu li a {
	display: block;
	color: #ffffff;
  text-decoration: none;
}
#main-menu .menu-container .menu li a span {
	display: block;
}
#main-menu .menu-container .menu ul {
	list-style: none;
	padding: 0;
	padding-left: 20px;
}
#main-menu .menu-container .menu ul li {
	padding-top: 0;
	margin-bottom: 10px;
	border-top: none;
}
#main-menu .menu-container .menu ul li a {
	color: #c3ac6b;
}

/*----------------
main menu - footer
-----------------*/

#main-menu .menu-footer {
	padding: 42px 10px 20px 56px;
}
#main-menu .menu-footer h3 {
  font-family: 'Lato', sans-serif;
	font-size: 40px;
	font-weight: 300;
	color: #c3ac6b;
	margin-bottom: 20px;
}
#main-menu .menu-footer .newsletter {
	margin-bottom: 25px;
  position: relative;
}
#main-menu .menu-footer .newsletter input.email {
  font-family: 'Lato', sans-serif;
  display: block;
  width: 100%;
  height: 42px;
  border-radius: 3px;
  background-color: #ffffff;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 700;
  color: #7a6d54;
  padding: 0 100px 0 10px;
}
#main-menu .menu-footer .newsletter .button {
  font-family: 'Lato', sans-serif;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  background-color: #c3ac6b;
  border: none;
  color: #fff;
  font-size: 16px;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 6px;
  text-align: center;
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
}
#main-menu .menu-footer .social-media a {
	font-size: 30px;
	color: #c0b9ad;
	margin-right: 15px;
}
</style>