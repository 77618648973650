<template>
  <div class="recent-deals">

      <!-- <h2>Recent Deals</h2>

      <div class="recent-deals-carousel">

        <div class="deal" v-for="deal in deals" :key="deal.id">
          <div class="deal-img" :style="{ 'background-image': 'url(' + deal.img + ')' }"></div>
          <div class="deal-content">
            <p>{{ deal.location }}</p>
            <p>{{ deal.price }}</p>
            <p>{{ deal.ltv }}</p>
          </div>
          <div class="deal-parties">
            <div class="party-broker">
              <img :src="deal.broker" alt="Broker profile">
            </div>
            <div class="party-company">
              <img :src="deal.company" alt="Company logo">
            </div>
          </div>
        </div>
        
      </div> -->

      <VueRssFeed :feedUrl="feedUrl" :name="name" :limit="limit" :category="category" />

  </div>
</template>

<script>
import VueRssFeed from 'vue-rss-feed';
export default {
  name: 'RecentDeals',
  components: {
    VueRssFeed
  },
  data() {
    return {
      feedUrl: "https://pulse.brydg.com/feed",
      name: "Recent Deals",
      limit: 5,
      category: "Recent Deals",
      // deals: [
      //   {
      //     id: 1,
      //     img: require("@/assets/deal-01.jpg"),
      //     location: "London N1",
      //     price: "£1,125,000",
      //     ltv: "64% LTV",
      //     broker: require("@/assets/peter-matthews.png"),
      //     company: require("@/assets/company.png")
      //   },
      //   {
      //     id: 2,
      //     img: require("@/assets/deal-02.jpg"),
      //     location: "London N1",
      //     price: "£1,125,000",
      //     ltv: "64% LTV",
      //     broker: require("@/assets/peter-matthews.png"),
      //     company: require("@/assets/company.png")
      //   },
      //   {
      //     id: 3,
      //     img: require("@/assets/deal-03.jpg"),
      //     location: "London N1",
      //     price: "£1,125,000",
      //     ltv: "64% LTV",
      //     broker: require("@/assets/peter-matthews.png"),
      //     company: require("@/assets/company.png")
      //   },
      //   {
      //     id: 4,
      //     img: require("@/assets/deal-01.jpg"),
      //     location: "London N1",
      //     price: "£1,125,000",
      //     ltv: "64% LTV",
      //     broker: require("@/assets/peter-matthews.png"),
      //     company: require("@/assets/company.png")
      //   },
      //   {
      //     id: 5,
      //     img: require("@/assets/deal-02.jpg"),
      //     location: "London N1",
      //     price: "£1,125,000",
      //     ltv: "64% LTV",
      //     broker: require("@/assets/peter-matthews.png"),
      //     company: require("@/assets/company.png")
      //   },
      //   {
      //     id: 6,
      //     img: require("@/assets/deal-03.jpg"),
      //     location: "London N1",
      //     price: "£1,125,000",
      //     ltv: "64% LTV",
      //     broker: require("@/assets/peter-matthews.png"),
      //     company: require("@/assets/company.png")
      //   }
      // ]
    }
  }
}
</script>
<style>
.recent-deals h2 {
  font-size: 1.5rem;
  color: #817878;
}
.recent-deals .articles-container {
  padding: 1.5rem 0;
}
.recent-deals .article {
  min-width: 15rem;
  background-color: transparent;
  border: none;
  border-radius: 1rem;
  overflow: hidden;
  padding: 0;
  margin-bottom: 0;
  margin-left: 1rem;
}
.recent-deals .article:last-of-type {
  margin-right: 1rem;
}
.recent-deals .image img {
  display: block;
}
.recent-deals .title {
  display: none;
}
@media only screen and (min-width: 768px) {
  .recent-deals h2 {
    font-size: 2.5rem;
    padding: 1rem 0;
  }
  .recent-deals .article {
    min-width: 40rem;
    border-radius: 2rem;
    margin-left: 2rem;
  }
  .recent-deals .article:last-of-type {
    margin-right: 2rem;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.recent-deals {
  padding: 1.5rem 0 0;
  text-align: center;
  overflow: hidden;
}
.recent-deals-carousel {
  display: flex;
  overflow: auto;
  padding: 1.5rem 0;
  padding-bottom: 3rem;
}
.recent-deals-carousel::-webkit-scrollbar {
	display: none;
}
.deal {
  min-width: 11rem;
  background: #fff;
  position: relative;
  border-radius: 20px;
  color: #584D34;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.01), 0px 32px 34px -31px rgba(0, 0, 0, 0.1);
  margin-left: 1rem;
}
.deal:last-child {
  margin-right: 1rem;
}
.deal-parties {
  display: flex;
  align-items: center;
  justify-content: center;
}
.deal-parties div {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  margin-bottom: -1.5rem;
  background: #fff;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.01), 0px 5px 19px -5px rgba(0, 0, 0, 0.3);
}
.deal-parties .party-broker {
  border: solid 3px #fff;
  margin-right: -.3rem;
  z-index: 1;
}
.deal-parties .party-company {
  margin-left: -.3rem;
}
.deal-parties div img {
  display: block;
}
.deal-img {
  height: 9rem;
  background-size: 150% auto;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}
.deal-content {
  padding: 1rem 0 .5rem 0;
}
.deal-content p {
  font-weight: 700;
}
.deal-content p:nth-of-type(1) {
  font-size: .6rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 0;
}
.deal-content p:nth-of-type(2) {
  font-size: 1.5rem;
  margin: 0;
}
.deal-content p:nth-of-type(3) {
  width: 46px;
  height: 46px;
  font-family: 'Lato', sans-serif;
  font-size: .8em;
  font-weight: 900;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 10px;
  background: #BA4A00;
  color: #fff;
  -webkit-border-radius: 50px;
  -webkit-border-top-right-radius: 25px;
  -moz-border-radius: 50px;
  -moz-border-radius-topright: 25px;
  border-radius: 50px;
  border-top-right-radius: 25px;
  padding-top: .4rem;
  margin: 0;
}
.deal-content p.gold {
  background: #C3AC6B !important;
}
@media only screen and (min-width: 768px) {
  .recent-deals-carousel {
    padding-bottom: 6rem;
  }
  .deal {
    min-width: 20rem;
    border-radius: 40px;
    margin-left: 2rem;
  }
  .deal:last-child {
    margin-right: 2rem;
  }
  .deal-parties div {
    width: 5rem;
    height: 5rem;
    margin-bottom: -2.5rem;
  }
  .deal-img {
    height: 20rem;
    border-radius: 40px 40px 0 0;
  }
  .deal-content {
    padding: 1.5rem 0 .5rem 0;
  }
  .deal-content p:nth-of-type(1) {
    font-size: .8rem;
  }
  .deal-content p:nth-of-type(2) {
    font-size: 2.5rem;
    margin: 0;
  }
  .deal-content p:nth-of-type(3) {
    width: 66px;
    height: 66px;
    font-size: 1em;
    right: 15px;
    top: 15px;
    padding: .9rem .9rem .7rem 1.1rem;
  }
}
</style>