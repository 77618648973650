<template>
  <div class="news">
    <!-- <div class="heading">
      <h2>In the news</h2>
    </div> -->
    <!-- <div class="content"> -->
      <!-- <div class="content-carousel">
        <div class="carousel-item">
          <p>Lorem ipsum dolor sit amet, assueverit delicatissimi mea ex, est ea offendit more...</p>
        </div>
        <div class="carousel-item">
          <p>Lorem ipsum dolor sit amet, assueverit delicatissimi mea ex, est ea offendit more...</p>
        </div>
        <div class="carousel-item">
          <p>Lorem ipsum dolor sit amet, assueverit delicatissimi mea ex, est ea offendit more...</p>
        </div>
      </div> -->
      <VueRssFeed :feedUrl="feedUrl" :name="name" :limit="limit" :excludedCategory="excludedCategory"/>
      <div class="content-subscribe">
        <form action="https://brydg.us13.list-manage.com/subscribe/post?u=60bcc3d9308ec5d8d42566461&amp;id=0e6e564faf" method="post" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <input type="email" value="" name="EMAIL" placeholder="Subscribe for newsletter" required>
          <input type="submit" value="Subscribe" name="subscribe">
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_60bcc3d9308ec5d8d42566461_0e6e564faf" tabindex="-1" value=""></div>
        </form>
      </div>
        
    <!-- </div> -->
  </div>
</template>

<script>
// import VueRssFeed from "/node_modules/vue-rss-feed/src/VueRssFeed.vue";
import VueRssFeed from 'vue-rss-feed';
export default {
  name: 'News',
  components: {
    VueRssFeed
  },
  data() {
    return {
      feedUrl: "https://pulse.brydg.com/feed",
      name: "In The News",
      limit: 10,
      excludedCategory: "Recent Deals"
    }
  }
}
</script>
<style>
.news h2 {
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
  text-align: center;
}
.news .article {
  display: flex;
  flex-direction: column;
  min-width: 11rem;
  word-wrap: break-word;
  background-color: #fff;
  border: none;
  border-radius: 1.5rem;
  margin-left: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  color: #454545;
  padding: 0;
  margin-bottom: 0;
}
.news .article:last-of-type {
  margin-right: 1rem;
}
.news .article > a {
  color: #454545;
}
.news .image {
 overflow: hidden;
 /* width: 40%; */
}
.news .image img {
  display: block;
  max-width: none;
  width: 100%;
  height: 100%;
  /* object-fit: cover */
}
.news .article .title {
  padding: 1rem;
  /* width: 60%; */
}
.news .article .title h3 {
  font-size: .7rem;
  line-height: 1rem;
}
.news .article .title a {
  font-size: .7rem;
  font-weight: 700;
  color: #C3AC6B;
}
.news a {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .news h2 {
    font-size: 2.5rem;
    padding: 1rem 0;
  }
  .news .article {
    min-width: 20rem;
    word-wrap: break-word;
    background-color: #fff;
    border: none;
    border-radius: 1.5rem;
    margin-left: 2rem;
    overflow: hidden;
    box-sizing: border-box;
    color: #454545;
    padding: 0;
    margin-bottom: 0;
  }
  .news .article:last-of-type {
    margin-right: 2rem;
  }
  .news .article .title h3 {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .news .article .title a {
    font-size: 1rem;
    font-weight: 700;
    color: #C3AC6B;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.news {
  background: #CBCBCB;
  padding: 20px 0;
  margin: -35px 1rem 1rem 1rem;
  padding-top: 50px;
  border-radius: 0 0 20px 20px;
}
.content-subscribe {
  padding: 1rem 2rem 0 2rem;
  position: relative;
}
.content-subscribe input[type="email"] {
  font-family: 'DM Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  display: block;
  width: 100%;
  border: none;
  border-radius: 40px;
  padding: .8rem 1.5rem;
  outline: none;
}
.content-subscribe input[type="submit"] {
  font-family: 'DM Sans', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  display: block;
  background: #C3AC6B;
  border: none;
  color: #fff;
  padding: .8rem 1.5rem;
  border-radius: 40px;
  margin: 1rem auto 0 auto;
  -webkit-appearance: none;
}
@media only screen and (min-width: 768px) {
  .news {
    margin: -35px 2rem 2rem 2rem;
  }
  .content-subscribe {
    padding: 2rem;
    position: relative;
  }
  .content-subscribe input[type="email"] {
    font-size: 1.5rem;
    padding: 1rem 2rem;
  }
  .content-subscribe input[type="submit"] {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    margin: 0;
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
}
</style>