<template>
  <div class="about">
    <div class="heading">
      <i class="fas fa-chevron-up"></i>
      <h2>About <img src="../assets/logo.svg" alt="Brydg" /></h2>
    </div>
    <div class="gallery">
      <a href="https://brydg.com" target="_blank"><img src="../assets/splash.svg" alt="Brydg Splash"></a>
      <a href="https://g.page/Brydg" target="_blank"><img src="https://maps.googleapis.com/maps/api/staticmap?center=Brydg+Capital&zoom=15&scale=2&size=300x300&maptype=roadmap&key=AIzaSyCfI9qZWmL3LXoCT9JWIOtUwmq51yRYjsw&format=jpg&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7CBrydg+Capital" alt="Map of Brydg location"></a>
    </div>
    <div class="tags">
      <a href="mailto:info@brydg.com" target="_blank"><i class="fas fa-envelope"></i> Email</a>
      <a href="tel:+442031377630"><i class="fas fa-phone-alt"></i> Call</a>
      <a href="https://outlook.office365.com/owa/calendar/Brydg2@qismat.onmicrosoft.com/bookings/" target="_blank"><i class="fas fa-mug-hot"></i> Meet</a>
      <a href="https://g.page/Brydg"><i class="fas fa-map-marker-alt"></i> Visit</a>
    </div>
    <div class="content">
      <!-- <p>Lorem ipsum dolor sit amet, assueverit delicatissimi mea ex, est ea offendit legendos, audiam alterum abhorreant eos cu. Eam in invidunt temporibus delicatissimi, <a href="https://brydg.com/about" target="_blank">more...</a></p> -->
      <p>The Brydg group of companies is the most advanced real estate lending experience available for institutional investors and loan originators.</p>
      <p>We believe the world of lending is broken and our mission is to help people unlock the value of tomorrow in their assets of today. This means providing credit in order to realise proﬁts of potential. <a href="https://brydg.com/about" target="_blank">More...</a></p>
    </div>
    <!-- <div class="socials">
      <a href="https://twitter.com/brydgcom" target="_blank"><i class="fab fa-twitter-square"></i></a>
      <a href="https://instagram.com/brydg_" target="_blank"><i class="fab fa-instagram-square"></i></a>
      <a href="https://www.linkedin.com/company/brydg/" target="_blank"><i class="fab fa-linkedin"></i></a>
      <a href="https://www.facebook.com/brydg/" target="_blank"><i class="fab fa-facebook-square"></i></a>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about {
  background: #fff;
  margin: 0 1rem;
  border-radius: 1.5rem;
  position: relative;
  z-index: 1;
}
.heading {
  padding: 1rem 0;
  position: relative;
  color: #817878;
  text-align: center;
}
.heading h2 img {
  vertical-align: middle;
  margin-left: .3rem;
  width: 40%;
}
.heading i {
  font-size: 1rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: rgba(0, 0, 0, .25);
  cursor: pointer;
}
.gallery {
  display: flex;
}
.gallery a {
  display: block;
  width: 50%;
}
.gallery a img {
  display: block;
  width: 100%;
  height: 100%;
}
.tags {
  padding: 1.5rem;
  white-space: nowrap;
  overflow: auto;
}
.tags::-webkit-scrollbar {
	display: none;
}
.tags a {
  display: inline-block;
  text-decoration: none;
  color: #C3AC6B;
  font-weight: 700;
  border: solid 2px #C3AC6B;
  padding: .5rem 1rem;
  border-radius: 20px;
  margin-right: .5rem;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.01), 0px 16px 16px -13px rgba(0, 0, 0, 0.1);
}
.content {
  padding-bottom: 2rem;
}
.content p {
  font-size: 1rem;
  margin: 1rem 2rem;
  line-height: 1.5rem;
  color: #454545;
}
.content p a {
  font-weight: 700;
  color: #C3AC6B;
  text-decoration: none;
}
.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}
.socials a {
  display: block;
  font-size: 30px;
  margin: 0 .5rem;
  color: #C3AC6B;
}
@media only screen and (min-width: 768px) {
  .about {
    margin: 0 2rem;
  }
  .heading h2 {
    font-size: 2.5rem;
  }
  .heading h2 img {
    width: 30%;
  }
  .heading i {
    font-size: 2rem;
  }
  .tags {
    padding: 2rem 5rem;
  }
  .tags a {
    font-size: 1.5rem;
    padding: .5rem 1.5rem;
    border-radius: 40px;
    margin-right: 1rem;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.01), 0px 16px 16px -13px rgba(0, 0, 0, 0.1);
  }
  .content {
    padding-bottom: 3rem;
  }
  .content p {
    font-size: 2rem;
    margin: 2.5rem 5rem;
    line-height: 3.5rem;
  }
  .socials {
    padding-bottom: 2rem;
  }
  .socials a {
    display: block;
    font-size: 50px;
    margin: 0 1rem;
    color: #C3AC6B;
  }
}
</style>