<template>
  <div class="app-store">
    <div class="heading">
      <h2>App Store</h2>
    </div>
    <div class="content">
      <div>
        <a href="https://brydg.com" target="_blank">
          <img src="../assets/diva.svg" alt="Diva logo">
          <h3>DIVA</h3>
          <p>Data, Insights, Visualisations and Analytics</p>
        </a>
      </div>
      <div>
        <a href="https://brydg.com/latitude" target="_blank">
          <img src="../assets/latitude.svg" alt="Latitude logo">
          <h3>LATITUDE</h3>
          <p>Local Area Transactional Intelligence Ticker </p>
        </a>
      </div>
      <div>
        <a href="https://flow.brydg.com" target="_blank">
          <img src="../assets/flow.svg" alt="Flow logo">
          <h3>FLOW</h3>
          <p>Financial Loan Origination Workspace</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppStore',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app-store {
  text-align: center;
}
.heading {
  padding: 1rem 0;
  color: #817878;
}
.heading h2 {
  font-size: 1.5rem;
}
.content {
  display: flex;
  align-items: center;
  overflow: auto;
}
.content::-webkit-scrollbar {
	display: none;
}
.content > div {
  padding: 1rem 0 4rem 0;
}
.content > div > a {
  min-width: 12rem;
  background: #FFFFFF;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.01), 0px 50px 52px -25px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin-left: 1rem;
  display: block;
  padding-bottom: 1.5rem;
  text-decoration: none;
}
.content > div > a:hover,
.content > div > a:active,
.content > div > a:visited {
  outline: none;
}
.content > div:last-child {
  margin-right: 1rem;
}
.content > div > a > img {
  display: block;
  margin: 0 auto;
  padding: 1rem 2.5rem 0 2.5rem;
}
.content > div > a > h3 {
  font-size: 1.5rem;
  letter-spacing: .2rem;
  color: #817878;
}
.content > div > a > p {
  font-size: .7rem;
  font-style: italic;
  color: #ADA694;
  padding: 0 1rem;
}
@media only screen and (min-width: 768px) {
  .heading h2 {
    font-size: 2.5rem;
  }
  .content {
    justify-content: space-between;
    padding: 0 2rem;
  }
  .content > div {
    padding-top: 2rem;
    flex-basis: 31%;
  }
  .content > div:last-child {
    margin-right: 0;
  }
  .content > div > a {
    margin-left: 0;
  }
  .content > div > a > img {
    padding: 2rem 1.5rem 0 1.5rem;
  }
  .content > div > a > h3 {
    font-size: 2rem;
  }
  .content > div > a > p {
    font-size: 1rem;
    padding: 0 2rem;
  }
}
</style>
