<template>
  <div class="inside-footer">

    <div class="flex-container">

        <div class="one box">
            <h5><a href="/capital" class="capital-trigger">Brydg Capital</a></h5>
            <p>Bespoke lending from £1m - £100m with advanced underwriting and dynamic loan management.</p>
        </div>

        <div class="two box">
            <h5><a href="/exchange" class="exchange-trigger">Brydg Services</a></h5>
            <p>Asset backed lending platform providing Originators one application to reach many lenders. Lenders have sophisticated loan management dashboard and have the control of being "Lenders of Record".</p>
        </div>

        <div class="three box">
            <h4><a href="https://flow.brydg.com" target="_blank">OX</a></h4>
            <h5>Originator Experience</h5>
            <p>Originators and intermediaries get instant decisions with one application to reach many Lenders.</p>
        </div>

        <div class="four box">
            <h4><a href="/lenders" target="_blank">LX</a></h4>
            <h5>Lender Experience</h5>
            <p>Ability to scale real estate backed lending with attractive IRRs and complete security as "Lender of Record".</p>
        </div>

        <div class="five box">
            <h4><a href="https://flow.brydg.com/investors/dashboard" target="_blank">IX</a></h4>
            <h5>Investor Experience</h5>
            <p>Investors have complete control and convenience through our sophisticated technology coupled with our team of real estate and credit veterans managing their loan investments.</p>
        </div>

        <div class="six box">
            <ul>
                <li><a href="/about">About us</a></li>
                <li><a href="/philanthropy">Philanthropy</a></li>
                <li><a href="https://pulse.brydg.com" target="_blank">Pulse Blog</a></li>
                <li><a href="https://brydg.eventbrite.com/o/brydgcom-17476202033" target="_blank">Events</a></li>
                <li><a href="/shop" target="_blank">Merchandise Shop</a></li>
            </ul>
        </div>

        <div class="seven box">
            <p>Nightingale House <br>65 Curzon Street <br>London, W1J 8PE</p>
            <p>+44 203 137 7630</p>
            <p><a href="mailto:info@brydg.com">info@brydg.com</a></p>
        </div>

    </div>

    <div class="wrapper">

        <div class="footer-socials">

            <div class="accredited-tag">
                <img style="padding-left:10px;" src="../assets/BBB_logo2.png" alt="British Business Bank logo">
                <p>Brydg is an accredited lender under the Coronavirus Business Interruption Loan Scheme (CBILS), provided by the <strong>Government-owned British Business Bank</strong> and the Secretary of State for Business, Energy and Industrial Strategy
                    (BEIS).
                </p>
            </div>

            <div class="newsletter">

                <div class="social-media">
                    <a href="https://www.facebook.com/brydg/" target="_blank"><i class="fab fa-facebook-square"></i></a>
                    <a href="https://twitter.com/brydgcom" target="_blank"><i class="fab fa-twitter-square"></i></a>
                    <a href="https://instagram.com/brydg_" target="_blank"><i class="fab fa-instagram"></i></a>
                    <a href="https://www.linkedin.com/company/brydg/" target="_blank"><i class="fab fa-linkedin"></i></a>
                    <a href="https://www.youtube.com/channel/UC1xkXZ7OHoqHDDQzw3Vr84w" target="_blank"><i class="fab fa-youtube"></i></a>
                    <a href="https://brydg.eventbrite.com/o/brydgcom-17476202033" target="_blank"><img style="width:25px;vertical-align:baseline;" src="../assets/eventbrite.png"></a>
                </div>

            </div>

        </div>

        <div class="footer-menu">
            <ul>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/cookies">Cookie Policy</a></li>
                <li><a href="/complaints">Complaints</a></li>
            </ul>
        </div>

        <div class="footer-copy">
            <div class="footer-copy-excerpt">
                <p>Brydg Capital Ltd. is a company registered in England and Wales under Company No. 0&shy;9034678, with its registered office at Nightingale House, 65 Curzon Street, London, W1J 8PE. Brydg Capital is authorised and regulated
                    by the Financial Conduct Authority as credit broker and not a consumer lender - FRN 739493. Brydg Capital may provide loans in relation to exempt agreements only.</p>
                <button>
                    <i class="fas fa-angle-down"></i>
                    <span v-if="!showFooter" @click="showFooter = true" class="footer-copy-more">More</span>
                    <span v-if="showFooter" @click="showFooter = false" class="footer-copy-hide">Hide</span>
                </button>
            </div>
            <div v-if="showFooter" class="footer-copy-content">
                <p>Brydg Services is a company registered in England and Wales under Company No. 10627092 with its registered office at Nightingale House, 65 Curzon Street, London, W1J 8PE.</p>
                <p>Investment through Brydg Capital Ltd. involves an investment in an interest rate receivable, from loans secured against property in the UK. Whilst the underlying loan is secured against property, remember, the value of your investment
                    can go down as well as up. Also, past returns are not necessarily a guide to future returns. The net return shown for each investment is the net return after fees but before any deduction for tax and the return is specific to each
                    loan.
                </p>
                <p>Borrowing through Brydg Capital Ltd. involves entering into a mortgage contract secured against investment property in England &amp; Wales as borrower. Your property may be repossessed if you do not keep up repayments on your mortgage.</p>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'InsideFooter',
  data: function() {
    return {
        showFooter: false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inside-footer {
    font-family: 'Lato', sans-serif;
	background: #f2f1ed;
	padding: 1rem 0 4rem 0;
}
.flex-container {
	padding: 0 1rem;
	margin-bottom: 1.5rem;
}
.inside-footer .box {
	border-bottom: solid 1px #d1cec8;
	padding: 1.5rem 1rem;
}
.inside-footer .box a {
	color: #7a6d54;
    text-decoration: none;
}
.three h4,
.four h4,
.five h4 {
	font-size: 50px;
	font-weight: 300;
	color: #7a6d54;
	margin: 0;
}
.one h5,
.two h5,
.three h5,
.four h5,
.five h5 {
	font-size: 15px;
	font-weight: 700;
	color: #7a6d54;
	margin-bottom: 6px;
}
.one p,
.two p,
.three p,
.four p,
.five p {
	font-size: 13px;
	font-weight: 300;
	color: #7a6d54;
}
.six ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.six li {
	font-size: 15px;
	font-weight: 700;
	margin-bottom: 10px;
}
.six li a {
	color: #7a6d54;
}
.seven p:first-of-type {
	font-size: 15px;
	font-weight: 700;
	color: #7a6d54;
	margin-bottom: 40px;
}
.seven p {
	font-size: 20px;
	font-weight: 300;
	color: #7a6d54;
	margin: 0;
}
.seven a {
	color: #7a6d54;
}
.footer-socials {
	font-size: 30px;
	color: #c0b9ad;
	margin-bottom: 20px;
}
.accredited-tag {
	display: flex;
	border-radius: 8px;
	overflow: hidden;
	max-width: 600px;
	background: #fff;
	align-items: center;
	margin: 0 1rem 1rem;
}
.accredited-tag p {
	width: 70%;
	padding: 15px 20px;
	margin: 0;
	font-size: 10px;
	color: #7a6d54;
	font-weight: 300;
}
.accredited-tag img {
	width: 35%;
}
.footer-socials .social-media {
	margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-socials .social-media a {
	color: #c0b9ad;
	margin-left: 15px;
}
.footer-socials .social-media a:first-child {
	margin-left: 0;
}
.footer-menu {
	margin-bottom: 20px;
}
.footer-menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
	font-family: 'Lato', sans-serif;
	font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-menu li {
	margin-left: 5px;
}
.footer-menu li:first-of-type {
	margin-left: 0;
}
.footer-menu li::before {
	content: "|";
	margin-right: 5px;
}
.footer-menu li:first-of-type::before {
	content: "";
	margin-right: 0;
}
.footer-menu li a {
	color: #7a6d54;
    text-decoration: none;
}
.footer-copy {
    padding: 0 2rem;
}
.footer-copy p {
	font-size: 13px;
	font-weight: 300;
	color: #7a6d54;
}
.footer-copy-excerpt button {
	padding: 0;
	font-size: 13px;
	font-weight: 300;
	color: #7a6d54;
	background-color: transparent;
	border: 0;
	outline: 0;
	-webkit-transition: all .3s;
	transition: all .3s;
    margin: 1rem 0;
}
.footer-copy-excerpt button i {
	width: 16px;
	height: 16px;
}
.footer-copy-excerpt button span {
	cursor: pointer;
}
.footer-copy-excerpt button span:hover {
	text-decoration: underline;
}
@media only screen and (min-width: 768px) {
	.inside-footer {
		
	}
	.flex-container {
		display: flex;
		justify-content: flex-start;
		padding: 0 1rem;
		margin-bottom: 1.5rem;
	}
	.inside-footer .box {
		-webkit-flex: 1; /* Safari 6.1+ */
		-ms-flex: 1; /* IE 10 */ 
		flex: 1;
		border-bottom: none;
		border-left: solid 1px #d1cec8;
		padding: 25px 10px 0 10px;
	}
	.inside-footer .box:first-of-type {
		border-left: none;
	}
	.accredited-tag {
		margin: 0 auto 1rem auto;
	}
	.accredited-tag p {
		font-size: 13px;
	}
	.footer-copy {
			padding: 1rem 2rem 3rem 2rem;
	}
}
</style>