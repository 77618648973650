<template>

  <div id="header">

    <button class="menu-trigger" @click="toggleMenu">
      <img src="../assets/menu.svg" alt="Menu">
    </button>

    <a href="/" class="logo">
      <img src="../assets/brydg.svg" alt="Brydg">
    </a>

    <a href="/" class="badge">
      <img src="../assets/badge.svg" alt="Logo" />
    </a>

  </div>

</template>

<script>
export default {
  name: 'Header',
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu');
    }
  }
  // data() {
  //   return {
  //     isMenuOpen: false
  //   }
  // },
  // methods: {
  //   toggleMenu() {
  //     this.isMenuOpen = !this.isMenuOpen;
  //     // const mainMenu = document.getElementById('main-menu');
  //     // mainMenu.style.display = "block";
  //   }
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem .5rem .5rem;
}
#header button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 1rem;
  /* padding: 0 1rem; */
}
#header .badge {
  margin-top: .3rem;
}
</style>