<template v-cloak>
  <InstallBanner />
  <Header @toggleMenu="isMenuOpen = !isMenuOpen" />
  <BrokerProfile />
  <LoanBook />
  <RecentDeals />
  <!-- <Latitude /> -->
  <!-- <Diva /> -->
  <About />
  <News />
  <AppStore />
  <Resources />
  <!-- <MainMenu /> -->
  <InsideFooter />
  <MainMenu :isMenuOpen="isMenuOpen" @toggleMenu="isMenuOpen = !isMenuOpen" />
  <Footer />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import InstallBanner from './components/InstallBanner.vue'
import Header from './components/Header.vue'
import BrokerProfile from './components/BrokerProfile.vue'
import LoanBook from './components/LoanBook.vue'
import RecentDeals from './components/RecentDeals.vue'
// import Latitude from './components/Latitude.vue'
// import Diva from './components/Diva.vue'
import About from './components/About.vue'
import News from './components/News.vue'
import AppStore from './components/AppStore.vue'
import Resources from './components/Resources.vue'
import InsideFooter from './components/InsideFooter.vue'
import MainMenu from './components/MainMenu.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    InstallBanner,
    Header,
    BrokerProfile,
    LoanBook,
    RecentDeals,
    // Latitude,
    // Diva,
    About,
    News,
    AppStore,
    Resources,
    InsideFooter,
    MainMenu,
    Footer
  },
  data() {
   return {
     isMenuOpen: false
   }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400&family=Lato:wght@300;400;700;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
[v-cloak] {
  display: none;
}
body {
  font-family: 'DM Sans', sans-serif;
  background: #E5E5E5;
}
#app {
  max-width: 1024px;
  margin: 0 auto;
  overflow: hidden;
  background: #E5E5E5;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  padding-bottom: 50px;
  position: relative;
  background: url("~@/assets/skyline2.png") #E5E5E5 no-repeat top 60px center;
  background-size: 100% 533px;
}
img {
  max-width: 100%;
  height: auto;
}
/* For small mobiles */
@media only screen and (max-width: 599px) {
  #app {
    background-size: 100% 483px;
  }
}
@media only screen and (min-width: 768px) {
  #app {
    background-size: 100% 938px;
  }
}
</style>
