<template>
  <div class="loanbook">

    <div class="box1">
      <img src="../assets/badge.svg" class="badge">
      <p>Brydg Lending Capacity</p> 
      <h3>£1.5 Billion</h3>
      <p>Over {{ lent }} Lent up to {{ date }}</p>
    </div> 

    <div class="container-2">

      <div class="ltv">
        <h4>Bridging</h4>
        <h5>Max LTV</h5>
        <p>75%</p>
      </div>

      <div class="ltc">
        <h4>Development</h4>
        <h5>Max LTC</h5>
        <p>85%</p>
      </div>

    </div>

    <div class="loans">
      <p>Loans £250k - £50m</p>
      <p>from 0.58% pm</p>
    </div>

    <div class="option">
      <p>Fast Track Option: 7 day turnaround</p>
      <button><a href="https://flow.brydg.com" target="_blank">Submit a Loan</a></button>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'LoanBook',
  data() {
    return {
      lent: "£1B",
      date: moment(new Date()).format("DD MMM YYYY")
    }
  },
  methods: {
    async getLoanBookData() {
      try {
        const endpoint = await fetch("https://flow.brydg.com/api/timeline");
        // const endpoint = await fetch("api/timeline");
        const loanBookData = await endpoint.json();
        const lent = loanBookData.lent;
        this.processLentFigure(lent);
      }
      catch(err) {
        // write silent error?
        console.log(err);
      }
    },
    processLentFigure(lent) {
      let valNum = Math.round(lent) + 551000000;
      // £1,003,711,940
      let valStr = valNum.toString();
      let valLen = valStr.length;
      let valCon = "K";
      if(valLen == 9)
        valCon = "M";
        valStr = (parseInt(valStr[3]) >= 5) ? valStr.slice(0, 2) + (parseInt(valStr[2]) + 1).toString() : valStr.slice(0, 3);
      if(valLen >= 10)
        valCon = "B";
        valStr = (valStr.slice(1, 3) == "00") ? valStr.slice(0, 1) : valStr.slice(0, 1) + "." + valStr.slice(1, 2);
      this.lent = "£" + valStr + valCon;
    }
  },
  mounted() {
    this.getLoanBookData();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loanbook {
  position: relative;
  color: #fff;
  text-align: center;
  align-items: center;
  /* margin: 0 1rem; */
  /* padding: 16rem 0; */
  padding: 2.5rem 1rem 0 1rem;
}
.box1 {
  background: rgba(0, 0, 0, .5);
  border-radius: 1rem 1rem 0px 0px;
  margin: 0 1rem;
  padding-bottom: 1.5rem;
}
.badge {
  border-radius: 50%;
  width: 60px;
  height: auto;
  position: relative;
  margin-top: -1.5rem;
}
.box1 p {
  font-size: 1rem;
  font-weight: 700;
}
.box1 h3 {
  font-size: 3rem;
}
.container-2 {
  display: flex;
  background:#fff;
  border-radius: 1rem 1rem 0px 0px;
  color: #000;
}
.container-2 h4 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: .3rem;
}
.container-2 h5 {
  font-size: 1.3rem;
  margin-bottom: 0;
}
.ltv {
  width: 50%;
  text-align: center;
  padding: 1rem 0;
}
.ltc {
  width: 50%;
  text-align: center;
  border-left: 1px #ccc solid;
  padding: 1rem 0;
}
.ltv p, .ltc p {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  line-height: 1;
}
.loans {
  color: #000;
  border-radius: 0px 0px 1rem 1rem;
  border-top: 1px #ccc solid;
  background-color: #fff;
  padding: 1rem 0;
  font-size: 1.3rem;
  font-weight: 700;
}
.option {
  background: #408AA1;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  text-align: center;
  align-items: center;
  padding: 1rem 1rem 2rem 1rem;
  margin: 0 1rem;
}
.option p {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
button {
  font-family: 'DM Sans', sans-serif;
  background: #FFFFFF;
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  border: none;
  padding: 1rem 2rem;
}
button a {
  text-decoration: none;
  color: #414141;
}
@media only screen and (min-width: 768px) {
  .loanbook {
    /* margin: 0 2rem; */
    padding: 4rem 2.5rem 0 2.5rem;
  }
  .box1, .option {
    margin: 0 3rem;
  }
  .badge {
    width: 100px;
    margin-top: -2.5rem;
  }
  .box1 p {
    font-size: 2rem;
  }
  .box1 h3 {
    font-size: 8rem;
  }
  .container-2 h4 {
    font-size: 1.5rem;
  }
  .container-2 h5 {
    font-size: 2.5rem;
  }
  .ltv, .ltc {
    padding: 3rem 0;
  }
  .ltv p, .ltc p {
    font-size: 8rem;
  }
  .loans {
    padding: 2rem 0;
    font-size: 3rem;
  }
  .option p {
    font-size: 2rem;
  }
  button {
    font-size: 2.5rem;
    padding: 1rem 6rem;
  }
}
</style>